import { render, staticRenderFns } from "./PostRegister.vue?vue&type=template&id=c467d9c6&scoped=true&"
import script from "./PostRegister.vue?vue&type=script&lang=js&"
export * from "./PostRegister.vue?vue&type=script&lang=js&"
import style0 from "./PostRegister.vue?vue&type=style&index=0&id=c467d9c6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c467d9c6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/horus-auth-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c467d9c6')) {
      api.createRecord('c467d9c6', component.options)
    } else {
      api.reload('c467d9c6', component.options)
    }
    module.hot.accept("./PostRegister.vue?vue&type=template&id=c467d9c6&scoped=true&", function () {
      api.rerender('c467d9c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/PostRegister.vue"
export default component.exports