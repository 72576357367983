<template>
  <div
    class="bg fill-height"
  >
  <v-container
    fluid
    class="fill-height ma-0"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-card
        elevation="17"
          raised
          class="pa-5 ma-1"
          width="410px"
      >
        <v-container class="ma-0 logo-wrapper">
            <img src="../assets/mappa_logo.png"/>
          </v-container>

        <v-card-title>Verifique seu e-mail</v-card-title>
        <v-card-text class="pr-0">
          <v-row
            class="ma-0"
          >
            <span
              class="subtitle-1">Enviamos e-mail para confirmação de cadastro. <br/>Clique no link enviado para acessar a Mappa.</span>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
  </div>
</template>

<script>
  export default {
    name: "PostRegister",
    data: () => ({
      stripeSessionID: '',
    }),
    methods: {
    },
    mounted() {
      if (this.$route.query.session_id) {
        this.stripeSessionID = this.$route.query.session_id
        this.$apiAuth.registerUserStripe(this.stripeSessionID)
      } 
    }
  }
</script>

<style scoped lang="scss">
  @import "../sass/mixins";

  .bg {
    background-image: url("../assets/login/background_image.jpg");
    background-size: cover;
  }

  .v-card {
    max-width: 410px;

    @include display-xs-only {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }

  .logo-wrapper {
    text-align: center;
  }

  .logo-wrapper img {
    height: 70px;
  }
</style>
