var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg fill-height" },
    [
      _c(
        "v-container",
        { staticClass: "fill-height ma-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-5 ma-1",
                  attrs: { elevation: "17", raised: "", width: "410px" }
                },
                [
                  _c("v-container", { staticClass: "ma-0 logo-wrapper" }, [
                    _c("img", {
                      attrs: { src: require("../assets/mappa_logo.png") }
                    })
                  ]),
                  _c("v-card-title", [_vm._v("Verifique seu e-mail")]),
                  _c(
                    "v-card-text",
                    { staticClass: "pr-0" },
                    [
                      _c("v-row", { staticClass: "ma-0" }, [
                        _c("span", { staticClass: "subtitle-1" }, [
                          _vm._v(
                            "Enviamos e-mail para confirmação de cadastro. "
                          ),
                          _c("br"),
                          _vm._v("Clique no link enviado para acessar a Mappa.")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }